<template>
    <v-container>

        <!-- Detail information -->
        <v-card>
            <v-card-text>
                <ul class="bullet-icon">

                    <li>
                    <v-icon small>mdi-car-back</v-icon>
                    When travel is completed, employess should submit completed travel expense report within 10 days.
                    Report should be accompanied by receipt for all individual expenses.
                    </li>

                    <li>
                    <v-icon small>mdi-car-traction-control</v-icon>
                    Employees who involved in an accident while travelling on business must promptly report the accident to their immediate supervisor.
                    </li>

                    <li>
                    <v-icon small>mdi-map-marker-distance</v-icon>
                    Please update the driving distance (mileage) once vehicle is returned at Booking Form (Click at pencil icon in view details of your booking on calendar).
                    </li>

                    <li>
                    <v-icon small>mdi-calendar-cursor</v-icon>
                    To make a reservartion, click on the relevant date on Calendar date and fill out the Booking Form.
                    </li>

                    <li>
                    <v-icon small>mdi-car-off</v-icon>
                    To cancel the booking, click on your booking date and click cancel button on summary details table. <span class="text-bold">Please noted, cancel booking button will hidden once event start. Please cancel before event.</span> 
                    </li>

                    <!-- <li>
                    <v-icon small>mdi-speedometer</v-icon>
                    Once returned, please ensure mileage information is updated for maintenance purposes.
                    </li> -->
                </ul>
            </v-card-text>
        </v-card>

        <v-divider></v-divider>

        <!-- Calendar -->
        <v-row class="fill-height">
            <v-col>
            <v-sheet class="calendarSizing" height="65">
                <v-toolbar
                flat
                >
                <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                bottom
                right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                :short-weekdays="false"
                :available-dates='{ start: new Date(), end: null }'
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="loadDataCalendar"
                ></v-calendar>
                <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                max-width="480px"
                >
                    <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                    >
                        <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-btn
                        icon
                        @click="editMyBooking(selectedEvent)"
                        v-if="me == selectedEvent.idUser"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-toolbar-title dense collapse v-html="selectedEvent.displayHeaderDetails"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="selectedOpen = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text >
                            <v-simple-table dense>
                                <template v-slot:default>
                                <tbody>
                                    <tr v-if="$vuetify.breakpoint.lgAndUp">
                                        <td colspan="2">

                                            <span v-for="(item, i) in globalListCar" :key="i">
                                                <!-- <span v-if="item.id === selectedEvent.idmeetingroom"> -->
                                                    <v-img
                                                    v-if="item.calendarColor === selectedEvent.color"
                                                    class="my-3"
                                                    height="150"
                                                    width="350"
                                                    :src="item.src? item.src : picLoad=false"
                                                    >
                                                    </v-img>
                                                <!-- </span> -->
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            Start Date
                                            <!-- <v-icon small>mdi-transfer-right</v-icon> -->
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.start)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            End Date
                                            
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.end)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-account</v-icon>
                                            User
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.userApply"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-cellphone-sound</v-icon>
                                            Contact
                                        </td>
                                        <td>
                                            <span v-if="selectedEvent.userContact != null" v-html="selectedEvent.userContact"></span>
                                            <span v-if="selectedEvent.userContact == null">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-card-text-outline</v-icon>
                                            Event
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.name"></span>
                                        </td>
                                    </tr>
                                    <tr v-if="me == selectedEvent.idUser && Date.parse(new Date(selectedEvent.start)) - Date.parse(new Date()) > 0">
                                    <!-- <tr> -->
                                        <td colspan="2" class="py-3">
                                            <v-btn
                                            small
                                            class="text-bold white--text"
                                            color="red"
                                            @click="warningDelete = true;"
                                            >
                                                Cancel Book
                                                <v-icon small class="ml-2">mdi-car-off</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                        <!-- <v-btn
                        small
                        class="text-bold"
                        color="primary"
                        @click="selectedOpen = false"
                        >
                            Close
                        </v-btn> -->
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>
        
        <!-- Label Coloring Car -->
        <div
        style="display:flex;"
        >
            <v-container>
                <v-row>
                   <span v-for="item in globalListCar" :key="item.calendarColor" style="margin-right:30px;">
                        <v-col>
                            <small>
                                <v-icon
                                :class="item.calendarColor+'--text mr-2'"
                                >
                                    <!-- {{item.icon}} -->
                                    mdi-car-side
                                </v-icon>
                                <span>{{item.label}}</span>
                            </small>
                        </v-col>
                    </span> 
                </v-row>
            </v-container>
        </div>


        <!-- Popup Booking -->
        <template>
            <v-dialog
            v-model="dialogAddDateCarBook"
            persistent
            scrollable
            max-height="10px"
            max-width="600px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Car Booking Form</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Checkbox Event -->
                            <v-col cols="12" class="py-0">
                                <v-card class="mb-5 mt-2">
                                    <template>
                                        <v-container fluid>
                                            <v-icon class="mr-1">mdi-car-select</v-icon> Event Car<span class="text-bold red--text"> *</span>  
                                            <v-radio-group row class="mt-2">
                                                <v-radio
                                                v-for="car in radioCarEvent"
                                                :key="car.radios1"
                                                :label="car.label"
                                                :color="car.color"
                                                :value="car.label"
                                                @change="eventCar(car.value)"
                                                small
                                                ></v-radio>
                                            </v-radio-group>

                                            <small class="text-bold">** Daily cars do not require approval.</small>
                                        </v-container>
                                    </template>
                                </v-card>
                            </v-col>

                            <v-col cols="12" class="pt-0">
                                <small v-if="objectPostDataForm.eventCar == ''">(Select event car first to enable this selection)</small>
                                <v-select
                                dense
                                :disabled="disableCompCar"
                                prepend-inner-icon="mdi-car-key"
                                :items="companyCar"
                                v-model="selectedCompanyCar"
                                @change="checkAvailableCar"
                                label="Company Car"
                                outlined
                                ></v-select>
                            </v-col>

                            <!-- Input Start Date -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-menu
                                v-model="menuStartDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="startDate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="startDate"
                                    @input="menuStartDt = false"
                                    :min="datePickerStartToday"
                                    @change="checkAvailableCar(null,'dt')"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Input Start Time -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-dialog
                                    ref="dialogStartTime"
                                    v-model="modalStartTime"
                                    :return-value.sync="startTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="startTime"
                                        label="Start Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.startTime"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    ampm-in-title
                                    v-if="modalStartTime"
                                    v-model="startTime"
                                    @change="checkAvailableCar"
                                    full-width
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modalStartTime = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialogStartTime.save(startTime); checkAvailableCar(startTime,'dt')"
                                    >
                                        OK
                                    </v-btn>
                                    </v-time-picker>
                                </v-dialog>   
                            </v-col>

                            <!-- Input End Date -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-menu
                                v-model="menuEndtDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="endDate"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="endDate"
                                    @input="menuEndtDt = false"
                                    :min="datePickerStartToday"
                                    @change="checkAvailableCar(null,'dt')"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Input End Time -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-dialog
                                    ref="dialogEndTime"
                                    v-model="modalEndTime"
                                    :return-value.sync="endTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="endTime"
                                        label="End Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.endTime"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    ampm-in-title
                                    v-if="modalEndTime"
                                    v-model="endTime"
                                    @change="checkAvailableCar"
                                    full-width
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modalEndTime = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialogEndTime.save(endTime);checkAvailableCar(startTime,'dt')"
                                    >
                                        OK
                                    </v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col >

                            <!-- Select Cars -->
                            <v-col cols="12" class="py-0">
                                <v-card class="mb-5">
                                    <template>
                                        <v-container fluid>
                                            <v-icon class="mr-1">mdi-car-multiple</v-icon> Select Available {{eventCarText}} Car<span class="text-bold red--text"> *</span>
                                            <template>
                                                <span class="ml-1" v-if="this.visibleCar">
                                                    <v-progress-circular
                                                    size="20"
                                                    indeterminate
                                                    color="primary"
                                                    ></v-progress-circular>
                                                </span>
                                            </template>
                                            <p class="mt-5 mb-0" v-if="radioCarType.length == 0">(No Car Available)</p>
                                            <v-radio-group
                                            row
                                            >
                                                <v-radio
                                                v-for="car in radioCarType"
                                                :key="car.radios2"
                                                :label="car.label"
                                                :color="car.colorCheckbox"
                                                :value="car.id"
                                                @change="chooseCar(car.id)"
                                                small
                                                ></v-radio>
                                            </v-radio-group>

                                            <v-divider v-if="visibleHelperContact == true" class="mb-3"></v-divider>

                                            <small v-if="visibleHelperContact == true">
                                                <v-icon small class="primary--text mr-2">mdi-key-variant</v-icon>
                                                <strong>Last used this car:</strong>  <br>
                                                <td class="tdMiniInfo-carBook-left px-2">
                                                    <span>
                                                        <v-icon small>mdi-account</v-icon> {{lastUsedName}}
                                                    </span>
                                                </td>
                                                <td class="tdMiniInfo-carBook-right px-2">
                                                    <span>
                                                        <v-icon small>mdi-phone</v-icon> {{lastUsedContact}}
                                                    </span>
                                                </td>
                                                
                                                
                                            </small>

                                            

                                            <v-divider class="my-3"></v-divider>

                                            <small v-if="visibleHelperContact == true">
                                                <v-icon small class="primary--text mr-2">mdi-information</v-icon>
                                                <strong>For any inquiries regarding the car booking application or cancellation,
                                                please contact:</strong> <br>
                                                <td class="tdMiniInfo-carBook-left px-2">
                                                    <span>
                                                        <v-icon small>mdi-account</v-icon> {{helperName.toUpperCase()}}
                                                    </span>
                                                </td>
                                                <td class="tdMiniInfo-carBook-right px-2">
                                                    <span>
                                                        <v-icon small>mdi-phone</v-icon> {{helperContact}}
                                                    </span>
                                                </td>
                                            </small>

                                            <v-card width="400px" class="mt-2">
                                                <v-img loading width="400px" :src="imgCarSelected">
                                                </v-img>
                                            </v-card>

                                            

                                        </v-container>
                                    </template>
                                </v-card>
                                
                            </v-col>

                            <!-- Input Event Name -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Event Name"
                                    maxlength="50"
                                    v-model="objectPostDataForm.eventName"
                                    :rules="rules.eventName"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Event Description -->
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    outlined
                                    rows="2"
                                    dense
                                    label="Event Description"
                                    maxlength="300"
                                    hint="*Reason of applying vehicle booking"
                                    v-model="objectPostDataForm.eventDescribe"
                                ></v-textarea>
                            </v-col>


                            <v-col>
                                <small class="text-bold blackk--text">
                                    **Name of applicant & applicant contact is automatically  display based on your profile data. Please make sure update your contact number at your profile.
                                    <v-icon small>
                                        mdi-account
                                    </v-icon>
                                </small>
                            </v-col>

                            <!-- Input/Display Applied User Name -->
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    prepend-inner-icon="mdi-account"
                                    label="Name of Applicant"
                                    v-model="objectPostDataForm.userApply"
                                ></v-text-field>
                            </v-col>

                            <!-- Input/Display Applied User Contact-->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    prepend-inner-icon="mdi-phone"
                                    @keypress="isNumber($event)"
                                    label="Applicant Contact"
                                    v-model="objectPostDataForm.userContact"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                
                <small><span class="text-bold red--text">*</span>indicates required field</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="cancelFillBooking"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                :disabled="carNotChoose"
                @click="submitCarBooking"
                >
                    Submit
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>


        <!-- Popup Update Booking (Milleage) -->
        <template>
            <v-dialog
            v-model="dialogEditDateCarBook"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span v-if="mileageAdaDop == null" class="text-h5">Update Mileage Car</span>
                <span v-if="mileageAdaDop != null">
                    <span class="text-h5">Mileage Car is Updated</span>
                    <v-icon class="ml-2 white--text">mdi-check-decagram</v-icon>
                </span>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-simple-table class="table-details-info">
                                    <template v-slot:default>
                                    <tbody>
                                        <tr
                                        v-for="item in displayDetails"
                                        :key="item.name"
                                        >
                                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                                <v-icon v-if="$vuetify.breakpoint.lgAndUp" class="icon-text blackIcon--text" small>
                                                    {{ item.icon }}</v-icon>{{ item.key }}
                                            </td>
                                            <td>

                                                <span v-if="item.value != 'PENDING' && item.value != 'APPROVED'  && item.attribute != 'screenshot'">
                                                    {{ item.value }}
                                                </span>

                                                <v-chip
                                                v-if="item.value == 'APPROVED' && item.attribute != 'screenshot'"
                                                color="success"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <v-chip
                                                v-if="item.value == 'PENDING' && item.attribute != 'screenshot'"
                                                color="pending"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <span v-if="item.value != 'PENDING' && item.attribute == 'screenshot'">
                                                    <a v-if="item.value != null" :href="item.value" target="_blank">
                                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                                    </a>
                                                    <a v-if="item.value == null" style="cursor:default;">
                                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                                    </a> 
                                                </span>
                                                    
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        

                        <v-card class="pa-5 mt-3" v-if="mileageAdaDop == null">
                            
                            <v-row >

                                <v-col cols="12">
                                    <strong>Update Mileage</strong>
                                </v-col>

                                <!-- Input Mileage -->
                                <v-col
                                cols="12"
                                sm="6"
                                class="pb-0"
                                >   
                                    <small><span class="red--text">**</span> This field only enable (can be updated) after car used (<span class="text-bold">after end datetime of booking car</span>)</small>
                                    <span class="d-flex">
                                        <v-text-field
                                            :disabled="takLehUpdateMileage"
                                            outlined
                                            dense
                                            prepend-inner-icon="mdi-speedometer"
                                            label="Latest Mileage"
                                            hint="*Insert mileage after vehicle is returned"
                                            maxlength="50"
                                            @keypress="isNumber($event)"
                                            v-model="jsonEditBookCar.mileagecar"
                                            @input="inputUpdMil"
                                        ></v-text-field>
                                        <strong class="ml-1 mt-3">KM</strong>
                                    </span>
                                    
                                    <!-- <small class="text-bold">
                                        <span class="red--text">**</span> Failure to update mileage information requested on this form may result in significant processing <strong>delays and/or the denial</strong> of car booking application for others MNJG employee.
                                    </small> -->
                                </v-col>

                                <!-- Upload Image -->
                                <v-col
                                cols="12"
                                sm="6"
                                class="pb-0"
                                >
                                    <small></small><br>
                                    <small>**Upload Image is Optional</small>
                                    <v-file-input
                                        :disabled="takLehUpdateMileage"
                                        id="imgMileage"
                                        outlined
                                        dense
                                        label="Upload Image (Milieage)"
                                        prepend-icon="mdi-camera"
                                        hint="*Upload image from mileage capture"
                                        v-model="imageMil"
                                        v-on:change="handleFileUpload( $event )"
                                        :loading="loadingImageUpload"
                                    ></v-file-input>
                                    <a v-if="jsonEditBookCar.screenshot != null" :href="jsonEditBookCar.screenshot" target="_blank">
                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                    </a>
                                    <a v-if="jsonEditBookCar.screenshot == null " style="cursor:default;">
                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                    </a> 
                                </v-col>

                            </v-row>

                        </v-card>

                        
                    </v-container>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="cancelUpdateBooking"
                    >
                        Close
                    </v-btn>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    :disabled="latestMileageUpdate"
                    @click="updateCarBooking"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </template>


        <!-- Popup Option Day Click Event -->
        <template>
            <v-dialog
            v-model="optionDayEvent"
            width="400"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Car Booking</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                View List
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="dialogAddDateCarBook = true; optionDayEvent = false;"
                            >
                                Book Car
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog
            v-model="singleOptionDayEvent"
            width="250"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Car Booking</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                View List
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>


        <!-- Cancel Booking Car -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; cancelBooking(selectedEvent)"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Success Booking Car -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccessCarBook"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">Car Booking is succesfully {{textSuccess}} in system.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="disableCompCar = true; dialogSuccessCarBook = false; dialogAddDateCarBook = false; dialogEditDateCarBook = false; warningDelete = false; loadDataCalendar();"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogErrorCarBooking"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                    <br>
                    <v-card-text>
                        <small class="primary--text">
                            <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                        </small> 
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogErrorCarBooking = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogWarning"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">
                        {{textWarning}}
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogWarning = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Reminder!! -->
        <template>
            <v-dialog
            v-model="dialogRemind"
            max-width="450"
            >
            <!-- max-width="650" -->
            <v-card>
                <v-card-title class="text-h5 primary--text">
                    <v-icon class="icon-text primary--text ml-3" >mdi-alert-circle</v-icon> Reminder
                </v-card-title>
                <v-card-text class="blackk--text">
                    <!-- Reset password link has been sent to your emails. <br> Please check immediately within 15 mins. -->
                    <v-container>

                        <v-row>

                            <v-col cols="12">

                                <div>
                                    <strong><v-icon dense class="red--text mr-2 mb-2">mdi-speedometer</v-icon>Mileage:</strong><br>
                                    Once returned, please ensure mileage information is <b>updated</b> for maintenance purposes. Mileage information will be constantly monitored by Car Manager for vehicle maintenance schedules and to prevent car from getting damaged.
                                </div>

                                <br>

                                <div>
                                    <strong><v-icon dense class="red--text mr-2 mb-2">mdi-car-off</v-icon>Cancel Booking:</strong><br>
                                    Cancel booking button will hidden once event start. Please cancel before event.
                                </div>

                                <!-- <v-card class="my-3" elevation="0" v-if="$vuetify.breakpoint.lgAndUp">
                                    <v-timeline
                                        align-top
                                        dense
                                    >

                                        <v-timeline-item
                                        color="teal lighten-3"
                                        small
                                        >
                                        <v-row class="pt-1">
                                            <v-col>
                                            <strong>Staff</strong>
                                            <div class="text-caption mb-2">
                                                <v-icon class="red--text mr-2">mdi-car</v-icon> Staff have borrowed company cars without updating mileage information.
                                            </div>
                                            <v-avatar>
                                                <img
                                                src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=Black&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light'
                                                />
                                            </v-avatar>
                                            <v-avatar>
                                                <img
                                                    src='https://avataaars.io/?avatarStyle=Circle&topType=LongHairBigHair&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Red&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light'
                                                />
                                            </v-avatar>
                                            <v-avatar>
                                                <img
                                                    src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads01&accessoriesType=Round&hairColor=Black&facialHairType=Blank&clotheType=BlazerSweater&clotheColor=Red&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light'
                                                />
                                            </v-avatar>
                                            </v-col>
                                        </v-row>
                                        </v-timeline-item>

                                        <v-timeline-item
                                        color="red dark-3"
                                        small
                                        >
                                        <v-row class="pt-1">
                                            <v-col>
                                            <strong>Car Manager</strong>
                                            <div class="text-caption mb-2">
                                                <v-icon class="red--text mr-2">mdi-car-traction-control</v-icon>
                                                When cars are not properly maintained, the driver may lose control of the cars due to a defective condition and thereby cause a car accident.
                                            </div>
                                            <v-avatar>
                                                <img
                                                src='https://avataaars.io/?avatarStyle=Circle&topType=Hijab&accessoriesType=Round&hatColor=Pink&clotheType=BlazerShirt&eyeType=Default&eyebrowType=AngryNatural&mouthType=Serious&skinColor=Light'
                                                />
                                            </v-avatar>
                                            </v-col>
                                        </v-row>

                                        </v-timeline-item>

                                    </v-timeline>
                                </v-card> -->

                            </v-col>

                        </v-row>

                    </v-container>
                    

                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogRemind = false; "
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    
    data: () => ({

        dialogRemind: true,

        textSuccess: '',
        textWarning: '',

        // Calendar
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        dummyData: [
            {
                color: "ssa2469a",
                start: "2022-03-04T00:00:00",
                end: "2022-03-05T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "ssa2369a",
                start: "2022-03-06T00:00:00",
                end: "2022-03-07T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "vfp8523",
                start: "2022-03-08T00:00:00",
                end: "2022-03-09T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "ss6955w",
                start: "2022-03-10T00:00:00",
                end: "2022-03-11T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "bme8395",
                start: "2022-03-12T00:00:00",
                end: "2022-03-13T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "vp2514",
                start: "2022-03-14T00:00:00",
                end: "2022-03-15T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "dda413",
                start: "2022-03-16T00:00:00",
                end: "2022-03-17T00:00:00",
                name: "Test Color",
                timed: true
            },
            {
                color: "pms8914",
                start: "2022-03-18T00:00:00",
                end: "2022-03-19T00:00:00",
                name: "Test Color",
                timed: true
            },
        ],
        storeDataDisplayCalendar: [],
        dialogAddDateCarBook: false,
        listColorCar: [
            {car: "Perodua Bezza (Red - SSA2469A)", class: "ssa2469a", icon: "mdi-car-side"},
            {car: "Perodua Bezza (Blue - SSA2369A)", class: "ssa2369a", icon: "mdi-car-side"},
            {car: "Perodua Axia (Blue - VFP8523)", class: "vfp8523", icon: "mdi-car-side"},
            {car: "Ford Ranger (Blue - SS6955W)", class: "ss6955w", icon: "mdi-car-side"},
            {car: "Isuzu DMax (Grey - BME8395)", class: "bme8395", icon: "mdi-car-side"},
            {car: "Perodua Axia (Silver - VP2514)", class: "vp2514", icon: "mdi-car-side"},
            {car: "Perodua Axia (White - DDA413)", class: "dda413", icon: "mdi-car-side"},
            {car: "Perodua Axia (White - PMS8914)", class: "pms8914", icon: "mdi-car-side"},
        ],
    

        // Object Data Form
        objectPostDataForm: {
            eventCar: '',
            status: '',
            selectedCarType: '',
            lebelColorType: '',
            eventName: '',
            eventDescribe: '',
            userApply: '',
            userContact: '',
            mileageTotal: '',
            imageMileage: null
        },

        // Store for combination date and time
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        startTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),


        // Set rule field is required
        rules: {
          eventName: [val => (val || '').length > 0 || 'This field is required'],
          startTime: [val => (val || '').length > 0 || 'Start time is required'],
          endTime: [val => (val || '').length > 0 || 'End Time is required'],
        },


        // Engines/Dialog/Listing related to data form
        radios1: null,
        radioCarEvent: [
            {label: "Daily Car", color: "primary", value: "dailyCar"},
            {label: "Project Car", color: "primary", value: "projectCar"},
        ],    
        menuStartDt: false,
        modalStartTime: false,
        datePickerStartToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuEndtDt: false,
        modalEndTime: false,
        radios2: null,
        
        picLoad: true,
        radioCarType: [],
        selectedEventCar: '',
        companyCar: [],
        selectedCompanyCar: '',
        optionDayEvent: false,
        singleOptionDayEvent: false,
        daySelected: null,
        imageMil: null,
        visibleCar: false,
        eventCarText: '',
        carNotChoose: true,
        disableCompCar: true,
        imgCarSelected: require('@/assets/img/officeCar/none.jpg'),
        helperName: null,
        helperContact: null,
        lastUsedName: null,
        lastUsedContact: null,
        visibleHelperContact: false,
        goToOptions: {
            duration: 10000,
            offset: 0,
            easing: 'easeInOutCubic',
        },

        // Dialog
        dialogSuccessCarBook: false,
        dialogErrorCarBooking: false,
        dialogWarning: false,
        dialogEditDateCarBook: false,

        // Edit Data Process
        jsonEditBookCar: {
            idcartype: "",
            startdate: "",
            enddate: "",
            status: "",
            screenshot: "",
            name: "",
            detailevent: "",
            mileagecar: "",
        },
        displayDetails: [
            {key: "Event Car", attribute: "eventCar", value: "", icon: "mdi-car-select"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "Start Date", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Car", attribute: "carType", value: "", icon: "mdi-car-multiple"},    
            {key: "Event Name", attribute: "name", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Details", attribute: "detailEvent", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Latest Mileage", attribute: "mileageCar", value: "", icon: "mdi-speedometer"},    
            {key: "Mileage Data Image", attribute: "screenshot", value: "", icon: "mdi-speedometer"},    
            {key: "Status", attribute: "status", value: "", icon: "mdi-check-decagram-outline"},    
        ],
        me: null,
        loadingImageUpload: false,
        latestMileageUpdate: true,
        takLehUpdateMileage: true,
        mileageAdaDop: null,

        // Delete/Cancel Car Booking Process
        warningDelete: false,

        myName: null,
        myNumber: null,


    }),
    methods:{

        load(){
            // console.log("load");
            this.me = this.$store.getters.user.idEmp;
            this.getMyDataProfile();
            this.getDataListCar();
        },

        getMyDataProfile(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                let data = response.data;
                for(let i in data){
                    if (data[i].idemp == this.$store.getters.user.idEmp) {
                        this.myName = data[i].name;
                        this.myNumber = data[i].phone;
                    }
                }
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },

        getDataListCar(){

            // this.globalListCar = [];

            // axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            // .then((response) => {
            //     // console.log(response.data);

            //     let data = response.data["cartype"]

            //     for(let i in data){
            //         data[i]["id"] = data[i]["idcartype"]

            //         let arr = data[i]["name"].split(" - ")[0].split(" ");
            //         for (let i in arr) {
            //         arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
            //         }
            //         let capatalizeNameCar = arr.join(" ");
            //         data[i]["label"] = capatalizeNameCar + " - " + data[i]["name"].split(" - ")[1]

            //         data[i]["calendarColor"] = data[i]["name"].split(" - ")[1].toLowerCase();
            //         data[i]["plate"] = data[i]["plateno"];
                    

            //         if(data[i]["idcartype"] == 1 || data[i]["idcartype"] == 5 || data[i]["idcartype"] == 6
            //         || data[i]["idcartype"] == 7 || data[i]["idcartype"] == 8){
            //             data[i]["company"] = "SCIENOTECH SDN BHD"
            //         }
            //         else if(data[i]["idcartype"] == 2 || data[i]["idcartype"] == 3){
            //             data[i]["company"] = "SPATIALWORKS SDN BHD"
            //         }
            //         else if(data[i]["idcartype"] == 4){
            //             data[i]["company"] = "SCIENTIFIC ENVIRONMENT SDN BHD"
            //         }

            //         if(data[i]["type"] == "DAILY"){
            //             data[i]["events"] = "dailyCar"
            //         }
            //         else if(data[i]["type"] == "PROJECT"){
            //             data[i]["events"] = "projectCar"
            //         }

            //     }

                
            // })
            // .catch(error => {
            //     console.log(error.response);
            // }) 
            
            this.loadListNameCompany();

        },

        // Calendar Vuetify Section
        viewDay ({ date }) {

            // console.log(date);

            // console.log(Date.parse(date) + " is " + new Date(date));
            // console.log(Date.parse(new Date()) + " is " + new Date());

            // Set Value input/field in Form Car Book
            this.daySelected = date;
            this.startDate = date;
            this.endDate = date;
            this.startTime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
            this.endTime = this.startTime;
            this.loadListAvailableCar(this.startDate,this.endDate,this.startTime,this.endTime);
            this.objectPostDataForm.userApply = this.myName;
            this.objectPostDataForm.userContact = this.myNumber;
            
            
            // kena set date clicked with current time.. By default selected date time = 08:00:00 Malaysia
            let date2 = date + "T" + (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 8);

            // If past day, jangan open popup car booking
            if(Date.parse(new Date(date2)) - Date.parse(new Date()) < 0)
            {

                this.singleOptionDayEvent = true;

                // Check kalau today or not 
                // if (new Date(date).toISOString().substr(0, 10) == new Date().toISOString().substr(0, 10)) {

                //     console.log("today?");
                //     this.optionDayEvent = true;
                // }
                // else{
                //     this.singleOptionDayEvent = true;
                //     console.log("pastt??");
                // }

            }
            else{
                this.optionDayEvent = true;
            }

            // Uncomment below code for zoom event day
            // this.focus = date
            // this.type = 'day'

        },
        zoomDaySelected(){
            this.focus = this.daySelected;
            this.type = 'day'
            this.optionDayEvent = false
            this.singleOptionDayEvent = false
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {

            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target

                let myInterval = setInterval(() => {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                    clearInterval(myInterval);
                }, 500);
            }

            

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        loadDataCalendar () {

            const d = new Date();
            let thisYear = d.getFullYear();
            const prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();

            // this.events = this.dummyData;
            this.events = [];

            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let storeDat = [];
                let data = response.data
                data.forEach(function(res){
                    res["color"] = res["color"].toLowerCase();
                    res["displayHeaderDetails"] = res["carType"].split(' (')[0] + ' (' + res["carType"].split('- ')[1];
                    storeDat.push(res);
                });

                this.events = storeDat;
                // console.log(this.events);
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },

        loadListAvailableCar(startDt,endDt,startT,endT){

            this.radioCarType = [];
            this.imgCarSelected = require('@/assets/img/officeCar/none.jpg');
            this.helperName = null;
            this.helperContact = null;
            this.lastUsedName = null;
            this.lastUsedContact = null;
            this.visibleHelperContact = false;

            this.visibleCar = true;
            let storeJap = [];

            axios.get(process.env.VUE_APP_API_URL + 'car/cekcars?startdt='+startDt+'T'+startT+':00&enddt='+endDt+'T'+endT+':00', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data

                // console.log(data);

                // console.log(data);
                
                for (let i = 0; i < data.length; i++) {
                    // console.log(data[i]);
                    for (let x = 0; x < this.globalListCar.length; x++) {

                        // Filter based on API = Local
                        if (data[i].plateno == this.globalListCar[x].plate) {
                            // console.log(data[i]);

                            

                            // Filter based on event car
                            if(this.selectedEventCar != null || this.selectedEventCar != ''){
                                
                                // console.log(this.selectedEventCar);
                                
                                if(this.selectedEventCar == this.globalListCar[x].events){

                                    
                                    
                                    // Filter based on company cars
                                    if(this.selectedCompanyCar != null || this.selectedCompanyCar != ''){
                                        
                                        if (this.selectedCompanyCar == this.globalListCar[x].company) {
                                            

                                            data[i]["label"] = this.globalListCar[x].label;
                                            data[i]["calendarColor"] = this.globalListCar[x].calendarColor;
                                            data[i]["colorCheckbox"] = this.globalListCar[x].colorCheckbox;
                                            data[i]["id"] = this.globalListCar[x].id;

                                            if ( data[i]['plateno'] != "VP 2514") {
                                                storeJap.push(data[i]);
                                            }
                                            this.visibleCar = false;
                                            this.radioCarType = storeJap;

                                            // console.log(this.radioCarType);
                                        }
                                        else{
                                            // storeJap = [];
                                            this.visibleCar = false;
                                        }
                                    }
                                    else{
                                        // storeJap = [];
                                        this.visibleCar = false;
                                    }
                                    

                                }
                                else{
                                    this.visibleCar = false;
                                    // storeJap = [];
                                }
                            }
                            else{
                                
                                storeJap = [];
                                this.visibleCar = false;
                            }
                            

                            // data[i]["label"] = this.globalListCar[x].label;
                            // data[i]["calendarColor"] = this.globalListCar[x].calendarColor;
                            // data[i]["colorCheckbox"] = this.globalListCar[x].colorCheckbox;
                            // data[i]["id"] = this.globalListCar[x].id;
                            
                        }
                    }
                }

                // console.log(this.radioCarType);

                
                // this.visibleCar = false;
                // if (this.radioCarType.length != 0) {
                //     this.visibleCar = false;
                // }
                
            })
            .catch(error => {
                console.log(error.response);
            }) 

            // console.log(process.env.VUE_APP_API_URL + 'car/cekcars?startdt='+startDt+'T'+startT+':00&enddt='+endDt+'T'+endT+':00');

        },

        loadListNameCompany(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data

                let nameCompany = [];
                
                // Filter Company Yang Ada Kereta je Muncul dlm list dropdown
                for(let i in data.company){
                    for(let x in this.globalListCar){
                        if (data.company[i].name == this.globalListCar[x].company) {
                            nameCompany.push(data.company[i].name);
                        }
                    }
                    
                }
                this.companyCar = nameCompany;
                // console.log(this.companyCar);
                
            })
            .catch(error => {
                console.log(error.response);
            }) 
        },




        // Process Fill Form Car Booking & Submit
        cancelFillBooking(){
            this.$refs.form.reset();
            this.dialogAddDateCarBook = false
            this.carNotChoose = true;
            this.disableCompCar = true;
            this.objectPostDataForm.eventCar = '';
            // this.openSummaryProfile();
        },

        eventCar(value){

            this.objectPostDataForm.eventCar = value
            this.selectedEventCar = ""
            this.carNotChoose = true;

            if (value == "projectCar") {
                this.objectPostDataForm.status = "PENDING";
                this.selectedEventCar = value;
                this.eventCarText = "Project"
                this.disableCompCar = false;
            }
            else if(value == "dailyCar"){
                this.objectPostDataForm.status = "N/A";
                this.selectedEventCar = value;
                this.eventCarText = "Daily";
                this.disableCompCar = false;
            }

            this.checkAvailableCar();
        },

        checkAvailableCar(a,endd){ //eslint-disable-line

            // console.log(endd);

            this.carNotChoose = true;

            if (Date.parse(this.startDate) > Date.parse(this.endDate)) {
                this.endDate = this.startDate;
            }

            // console.log("Start Date: "+this.startDate);
            // console.log("End Date: "+this.endDate);
            // console.log("Start Time: "+this.startTime);
            // console.log("End Time: "+this.endTime);

            if (endd == "dt") {
                if(Date.parse(this.startDate+"T"+this.startTime) < Date.parse(this.endDate+"T"+this.endTime)){
                    //start is less than End
                }else{
                    //end is less than start
                    this.textWarning = "Please select valid end datetime."
                    this.dialogWarning = true
                }
            }

            this.loadListAvailableCar(this.startDate,this.endDate,this.startTime,this.endTime);
        },

        chooseCar: function(idCar){
            this.objectPostDataForm.selectedCarType = idCar;

            this.carNotChoose = false;


            // Filter All Car Events
            let allSameCar = [];
            let allStartDateSameCar = [];
            for(let i in this.events){
                for(let x in this.globalListCar){
                    if (this.globalListCar[x].id == idCar) {

                        if (this.globalListCar[x].calendarColor == this.events[i].color) {
                            allSameCar.push(this.events[i]);
                            allStartDateSameCar.push(this.events[i].start);
                        }
                        
                    }
                }
            }

            // check last date book
           let dateUserSelected = this.startDate+"T"+this.startTime;
           let ClosestDatePrev = this.findClosestPrevDate(allStartDateSameCar,dateUserSelected)
            // and check which car?
            for(let i in allSameCar){
                if (allSameCar[i].start == ClosestDatePrev) {
                    this.lastUsedName = allSameCar[i].userApply;
                    this.lastUsedContact = allSameCar[i].userContact;
                }
            }

            for(let i in this.globalListCar){
                if (this.globalListCar[i].id == idCar) {
                    this.imgCarSelected = this.globalListCar[i].src;
                    this.helperName = this.globalListCar[i].userApproval;
                    this.helperContact = this.globalListCar[i].userContact;
                    this.visibleHelperContact = true;
                }
            }



            // console.log(this.objectPostDataForm);
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        submitCarBooking(){

            if(Date.parse(this.startDate+"T"+this.startTime) < Date.parse(this.endDate+"T"+this.endTime)){
                //start is less than End
                if(this.objectPostDataForm.eventCar != '' && this.objectPostDataForm.selectedCarType != '' && this.objectPostDataForm.eventName != ''){

                    if(this.objectPostDataForm.eventCar != ''
                    && this.objectPostDataForm.selectedCarType != ''
                    && this.objectPostDataForm.eventName != null){

                        let jsonBookCar = {
                            idcartype: this.objectPostDataForm.selectedCarType,
                            startdate: this.startDate + "T" + this.startTime + ":00",
                            enddate: this.endDate + "T" + this.endTime + ":00",
                            timed: true,
                            status: this.objectPostDataForm.status,
                            screenshot: this.objectPostDataForm.imageMileage,
                            name: this.objectPostDataForm.eventName,
                            detailevent: this.objectPostDataForm.eventDescribe,
                            mileagecar: this.objectPostDataForm.mileageTotal,
                        }

                        // console.log(jsonBookCar);

                        axios.post(process.env.VUE_APP_API_URL + "car/book",jsonBookCar,
                        {
                            headers: {
                            "Authorization": 'Bearer ' + this.$store.getters.user.token,
                            // 'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                            }
                        })
                        .then(response => { //eslint-disable-line
                            // console.log(response);
                            this.textSuccess = "book"
                            this.dialogSuccessCarBook = true;

                            this.loadDataCalendar();
                            bus.$emit('changeIt', 'loadNotify');
                            bus.$emit('changeIt', 'loadDataTableCar');
                            // this.$refs.form.reset();
                            this.cancelFillBooking();
                        })
                        .catch(e => {
                            console.log(e.response);
                            this.dialogSuccessCarBook = false;
                            this.dialogErrorCarBooking = true;
                            // window.loadDt = false;
                        })

                    }
                    else{

                        let self = this
                        setTimeout(function () {
                            if (self.$refs.form.validate()){
                            //other codes
                            // alert('submitted')
                            }  
                        })
                        this.textWarning = "Please fill out all of required field."
                        this.dialogWarning = true
                        

                    }

                    

                    // Close popup
                    // this.dialogAddDateCarBook = false;

                    // this.$refs.form.reset();
                }else{
                    let self = this
                    setTimeout(function () {
                        if (self.$refs.form.validate()){
                        //other codes
                        // alert('submitted')
                        }  
                    })
                    this.textWarning = "Please fill out all of required field."
                    this.dialogWarning = true
                }


            }else{
                //end is less than start
                this.textWarning = "Please select valid end datetime."
                this.dialogWarning = true
            }

            

            // console.log(this.events);

        },


        // Process Edit Data Car Booking
        // Enable button update latestMileageUpdate
        inputUpdMil(e){

            let text = '';
            text = e;

            if (text != '' && text != null ) {
                this.latestMileageUpdate = false;
            }
            else{
                this.latestMileageUpdate = true;
            }
        },

        editMyBooking(data){
            console.log(data);

            if (data.mileageCar != null) {
                this.mileageAdaDop = data.mileageCar;
            }
            else{
                this.mileageAdaDop = null;
            }
            // console.log(new Date(data.end));


            // Check Date Future atau Present atau Past
            // this.takLehUpdateMileage = false;

            // IF dah lepas dari masa sekarang
            if(Date.parse(new Date(data.end)) - Date.parse(new Date()) < 0)
            {
                this.takLehUpdateMileage = false;
            }
            else{
                this.takLehUpdateMileage = true;
            }


            
            let keyData = Object.keys(data)

            // Json For Sending to API Edit
            for(let i in this.globalListCar){
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                }
            }
            this.jsonEditBookCar.startdate = data.start;
            this.jsonEditBookCar.enddate = data.end;
            this.jsonEditBookCar.timed = true;
            this.jsonEditBookCar.status = data.status;
            this.jsonEditBookCar.screenshot = null;
            this.jsonEditBookCar.name = data.name;
            this.jsonEditBookCar.detailevent = data.detailEvent;
            this.jsonEditBookCar.mileagecar = "";

            // Json just for display
            for (let i = 0; i < this.displayDetails.length; i++) {

                this.displayDetails[i].value = "";
                
            }

            let myInterval = setInterval(() => {
                
                // console.log(keyData);
                for(let i in this.displayDetails){
                    for(let x in keyData){
                        if (this.displayDetails[i].attribute == keyData[x]) {
                            
                            if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                                this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                            }
                            else if (keyData[x] == "userContact") {
                                if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                    this.displayDetails[i].value = data[keyData[x]];
                                }
                                else{
                                    this.displayDetails[i].value = "-";
                                }
                            }
                            else if (keyData[x] == "detailEvent") {
                                if (data[keyData[x]] != null  && data[keyData[x]] != "") {
                                    this.displayDetails[i].value = data[keyData[x]];
                                }
                                else{
                                    this.displayDetails[i].value = "-";
                                }
                            }
                            else if (keyData[x] == "mileageCar") {
                                if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                    this.displayDetails[i].value = data[keyData[x]];
                                }
                                else{
                                    this.displayDetails[i].value = "-";
                                }
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                    }

                    if (this.displayDetails[i].attribute == "eventCar") {
                        if (data.status == "N/A") {
                            this.displayDetails[i].value = "Daily Car"
                        }
                        else{
                            this.displayDetails[i].value = "Project Car"
                        }
                    }

                }
                
                this.dialogEditDateCarBook = true;

                clearInterval(myInterval);
            }, 500);


            

        },

        handleFileUpload(data){

            this.loadingImageUpload = true;

            let formData = new FormData();

            formData.append("files", data);

            axios.post(process.env.VUE_APP_API_URL + `employees/upload`, formData, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                // console.log(response);
                this.jsonEditBookCar.screenshot = response.data.filepath;
                // console.log(this.jsonEditBookCar.mileagecar);
                this.loadingImageUpload = false;
            })
            .catch((error) => {
                console.log(error.response);
                this.loadingImageUpload = false;
            });
        },

        cancelUpdateBooking(){
            this.dialogEditDateCarBook = false;
            this.$refs.form.reset();
        },

        updateCarBooking(){

            // console.log(this.jsonEditBookCar);

            if (this.jsonEditBookCar.screenshot == "") {
                this.jsonEditBookCar.screenshot = null;
            }

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.textSuccess = "update";
                this.dialogSuccessCarBook = true;
                bus.$emit('changeIt', 'loadNotify');
                bus.$emit('changeIt', 'loadDataTableCar');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessCarBook = false;
                this.dialogErrorCarBooking = true;
            })
        },

        cancelBooking(data){
            // console.log(data);

            let idCar = null;
            for(let i in this.globalListCar){
                // console.log(this.globalListCar[i].label);
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    idCar = this.globalListCar[i].id
                }
            }

            // console.log(idCar);

            axios.get(process.env.VUE_APP_API_URL + "car/delbookcar?idcartype="+idCar+"&startdate="+data.start+"&enddate="+data.end,
            {
                headers: {
                    "Authorization": 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.textSuccess = "deleted";
                this.dialogSuccessCarBook = true;
                this.loadDataCalendar();
                bus.$emit('changeIt', 'loadNotify');
                bus.$emit('changeIt', 'loadDataTableCar');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessCarBook = false;
                this.dialogErrorCarBooking = true;
            })
        }

        
    },
    mounted(){
        this.load();
        this.objectPostDataForm.userApply = this.$store.getters.user.name;
        this.objectPostDataForm.userContact = this.$store.getters.user.phone;
    },
    created (){
        bus.$on('changeIt', (data) => {

            if (data == "loadDataCalendar") {
                this.loadDataCalendar();
            }

        })
    }
}

</script>

<style lang="scss">
@import '~scss/main';

.tdMiniInfo-carBook-left{
    border: 1px solid #c1c1c1;
    border-radius: 3px 0px 0px 3px;
}

.tdMiniInfo-carBook-right{
    border: 1px solid #c1c1c1;
    border-radius: 0px 3px 3px 0px;
}

</style>