<template>
    <v-container>

        <!-- <v-card class="pa-5">
            <template>
                <v-carousel
                    interval="5000"
                    cycle
                    height="auto"
                    show-arrows-on-hover
                >
                    <v-carousel-item
                    v-for="(slide, i) in urlCarOffice"
                    :key="i"
                    >
                        <v-icon class="icon-text blackk--text pb-2">mdi-car-side</v-icon><span class="text-bold" v-text="slide.name"></span>
                        <v-img contain :src="slide.src"></v-img>
                        
                    </v-carousel-item>
                </v-carousel>
            </template>
        </v-card> -->

        <v-card :class="$vuetify.breakpoint.mdAndDown ? '' : 'main-infocar-card'">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="9">

                        <v-card>

                            <v-img class="pa-5" width="auto" :src="displayCataCarSelected.src">

                            </v-img>

                        </v-card>

                        <v-container>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-car</v-icon>
                                        Car:
                                    </strong>
                                    <span>
                                        {{displayCataCarSelected.name}}
                                    </span>
                                </v-col>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-account-check</v-icon>
                                        Approval:
                                    </strong>
                                    <span v-html="displayCataCarSelected.userApproval"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-invert-colors</v-icon>
                                        Color:
                                    </strong>
                                    <span v-html="displayCataCarSelected.colorCar"></span>
                                </v-col>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-phone</v-icon>
                                        Contact:
                                    </strong>
                                    <span v-html="displayCataCarSelected.userContact"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon>mdi-numeric</v-icon>
                                        Plate No:
                                    </strong>
                                    <span v-html="displayCataCarSelected.plate"></span>
                                </v-col>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-car-select</v-icon>
                                        Event Car:
                                    </strong>
                                    <span v-html="displayCataCarSelected.events"></span>
                                </v-col>
                            </v-row>
                            <v-row>

                            </v-row>
                        </v-container>
                        
                    </v-col>
                    <v-col cols="12" sm="3">

                        <v-card :style="$vuetify.breakpoint.mdAndDown ? 'height: calc(100vh - 320px);' : 'height: calc(100vh - 255px);'">

                            <v-card-text
                            style="font-size:0.8em;font-weight:bold;"
                            v-if="$vuetify.breakpoint.mdAndDown"
                            >
                            Scrolldown and select any car to view the car information. <v-icon color="primary">mdi-gesture-swipe-vertical</v-icon>
                            </v-card-text>

                            <v-form>

                                <v-container :style="$vuetify.breakpoint.mdAndDown ? 'height: calc(100vh - 450px);' : 'height: calc(100vh - 255px);'" style="" class="scroll">
                                    <div
                                    height="400px"
                                    class="list-onecar my-2 pa-3"
                                    v-for="item in globalListCar"
                                    :key="item.value"
                                    @click="displayDetailsCar(item)"
                                    >
                                
                                        <v-img height="auto" width="300" :src="item.src"> 
                                        </v-img>

                                        <span v-html="item.label"></span>
                                    
                                    </div>
                                </v-container>

                            </v-form>

                        </v-card>

                    </v-col>
                </v-row>
            </v-container>
        </v-card>

    </v-container>
</template>

<script>

export default {
    data: () => ({

        // Tab Info Car
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],

        displayCataCarSelected: {},

        imageSelected: '',

    }),
    mounted(){
        // this.load();
        this.displayDetailsCar(this.globalListCar[0])
        // console.log("hello?",this.globalListCar);
    },
    methods: {

        // General
        // load(){
        //     // console.log("Load");
        // },

        displayDetailsCar(data){

            data.events = data.events == "dailyCar"? "Daily Car" : "Project Car"

            this.displayCataCarSelected = data;

            // this.displayCataCarSelected.src = data.src;
            // this.displayCataCarSelected.name = data.label.split(" (")[0];
            // this.displayCataCarSelected.color = data.color;
            // this.displayCataCarSelected.plate = data.plate;
            // this.displayCataCarSelected.approval = data.userApproval;
            // this.displayCataCarSelected.contact = data.userContact;
            // this.displayCataCarSelected.event = data.events;
        }


    },
    
}


</script>

<style lang="scss">
@import '~scss/main';

.main-infocar-card{
    // height: calc(100vh - 230px);
    height: auto !important;
}

.list-onecar{
    border-bottom: 0.5px solid #dcdcdc;
    cursor: pointer;
}

.list-onecar:hover{
    background: #dcdcdc;
}

.scroll {
    overflow-y: scroll;
    height: 400px;
    padding: 20px;
}

</style>