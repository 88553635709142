<template>

    <v-container>

        <!-- Detail information -->
        <v-card>
            <v-card-text>
                <ul class="bullet-icon">

                    <li>
                    <v-icon small>mdi-car-off</v-icon>
                    To cancel the booking, click on cancel car icon on table list. <span class="text-bold">Please noted, cancel booking button will disabled once event start. Please cancel before event.</span> 
                    </li>

                    <li>
                    <v-icon small>mdi-speedometer</v-icon>
                    Mileage car can be updated after car used by click on mileage icon on table list.
                    </li>
                    <li>
                    <v-icon small>mdi-help-circle</v-icon>
                    For any inquiries regarding the car booking application or cancellation, please contact car approval. (List of approval at Info Tab)
                    </li>
                </ul>
            </v-card-text>
        </v-card>

        <!-- Details Table -->
        <v-card class="mt-5 darkTable">
            <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchDtCarBook"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                dense
                class="shrink"
            ></v-text-field>
            </v-card-title>

            <v-data-table
            id="dtCarBook"
            class="dtCarBook"
            :items-per-page="5"
            :headers="headerCarBooking"
            :items="filteredData"
            :search="searchDtCarBook"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            >
                <template v-for="(header, i)  in headerCarBooking" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>
                    
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                <template v-slot:[`item.mil`]="{ item }">

                    
                    <span
                    v-if="item.statusMileageUpdated != 'Reject' && item.statusMileageUpdated != 'InUse'"
                    style="cursor:pointer !important;" 
                    @click="editMyMileage(item,item.statusMileageUpdated)"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on"
                            >
                                <v-icon
                                :color="item.mileageCar == null? 'red' : 'success'"
                                v-html="item.mil"
                                >
                                </v-icon>
                                <strong
                                :class="item.mileageCar == null? 'red--text' : 'success--text'"
                                v-html="item.mileageCar == null? ' !' : ''"
                                >
                                </strong>
                                <v-icon
                                small
                                :class="item.mileageCar == null? 'red--text' : 'ml-1 success--text'"
                                v-html="item.mileageCar == null? '' : 'mdi-check'"
                                >
                                </v-icon>
                            </span>
                        </template>
                        <span v-html="item.mileageCar == null? 'Mileage is not updated. Click to update mileage' : 'Mileage is updated'"></span>
                        </v-tooltip>
                        
                    </span>
                    <span
                    v-if="item.statusMileageUpdated == 'InUse'"
                    style="cursor:pointer !important;" 
                    @click="editMyMileage(item)"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on"
                            >
                                <v-icon
                                color="pending"
                                v-html="item.mil"
                                >
                                </v-icon>
                                <!-- <span class="pending--text">
                                    In Use..
                                </span> -->
                                <strong class="pending--text">
                                    . . .
                                </strong>
                                <!-- <span
                                v-html="item.mileageCar == null? 'Not Updated' : 'Updated'"
                                :class="item.mileageCar == null? 'red--text text-bold' : 'success--text text-bold'"
                                ></span> -->
                            </span>
                        </template>
                        <span>Click to update mileage after car used..</span>
                        </v-tooltip>
                        
                    </span>
                    <span
                    v-if="item.statusMileageUpdated == 'Reject'"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on"
                            >
                                <v-icon
                                color="grey"
                                >
                                mdi-cancel
                                </v-icon>
                                <!-- <span class="grey--text">
                                    Cancel
                                </span> -->
                            </span>
                        </template>
                        <span>Booking is rejected</span>
                        </v-tooltip>
                        
                    </span>
                </template>

                <template v-slot:[`item.cancelCar`]="{ item }">

                    <span
                    v-if="Date.parse(new Date(item.start)) - Date.parse(new Date()) > 0"
                    >
                       <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                color="red"
                                dense
                                v-html="item.cancelCar"
                                @click="confirmationDelete(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                </v-icon>
                            </template>
                            <span>Cancel Car Booking</span>
                        </v-tooltip> 
                    </span>

                    <span
                    v-if="Date.parse(new Date(item.start)) - Date.parse(new Date()) < 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                dense
                                v-html="item.cancelCar"
                                v-bind="attrs"
                                v-on="on"
                                >
                                </v-icon>
                            </template>
                            <span>The event has already started/passed.</span>
                        </v-tooltip>
                    </span>
                    

                </template>

                

            </v-data-table>
        </v-card>


        <!-- Popup Update Mileage -->
        <template>
            <v-dialog
            v-model="dialogEditMileage"
            persistent
            scrollable
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Update Mileage Car</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-card class="pa-5 mt-3">
                            
                            <v-row >

                                <v-col cols="12">
                                    <strong>Update Mileage</strong>
                                </v-col>

                                <!-- Input Mileage -->
                                <v-col
                                cols="12"
                                sm="6"
                                class="pb-0"
                                >   
                                    <small><span class="red--text">**</span> This field only enable (can be updated) after car used (<span class="text-bold">after end datetime of booking car</span>)</small>
                                    <span class="d-flex">
                                        <v-text-field
                                            :disabled="takLehUpdateMileage"
                                            outlined
                                            dense
                                            prepend-inner-icon="mdi-speedometer"
                                            label="Latest Mileage"
                                            hint="*Insert mileage after vehicle is returned"
                                            maxlength="50"
                                            @keypress="isNumber($event)"
                                            v-model="jsonEditBookCar.mileagecar"
                                            @input="inputUpdMil"
                                        ></v-text-field>
                                        <strong class="ml-1 mt-3">KM</strong>
                                    </span>
                                    
                                    <!-- <small class="text-bold">
                                        <span class="red--text">**</span> Failure to update mileage information requested on this form may result in significant processing <strong>delays and/or the denial</strong> of car booking application for others MNJG employee.
                                    </small> -->
                                </v-col>

                                <!-- Upload Image -->
                                <v-col
                                cols="12"
                                sm="6"
                                class="pb-0"
                                >
                                    <small></small><br>
                                    <small>**Upload Image is Optional</small>
                                    <v-file-input
                                        :disabled="takLehUpdateMileage"
                                        id="imgMileage"
                                        outlined
                                        dense
                                        label="Upload Image (Milieage)"
                                        prepend-icon="mdi-camera"
                                        hint="*Upload image from mileage capture"
                                        v-model="imageMil"
                                        v-on:change="handleFileUpload( $event )"
                                        :loading="loadingImageUpload"
                                    ></v-file-input>
                                    <a v-if="jsonEditBookCar.screenshot != ''" :href="jsonEditBookCar.screenshot" target="_blank">
                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                    </a>
                                    <a v-if="jsonEditBookCar.screenshot == '' " style="cursor:default;">
                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                    </a> 
                                </v-col>

                            </v-row>

                        </v-card>

                        
                    </v-container>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="cancelUpdateBooking"
                    >
                        Close
                    </v-btn>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    :disabled="latestMileageUpdate"
                    @click="updateCarBooking"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>


        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDisplayDetails"
            persistent
            scrollable
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Car Booking Form</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>
                                
                                <span
                                v-if="item.value != 'APPROVED' && item.value != 'REJECTED' && item.value != 'PENDING' && item.key != 'Mileage Image'"
                                >
                                {{ item.value }}
                                </span>

                                <v-chip
                                v-if="item.value == 'APPROVED' || item.value == 'REJECTED' || item.value == 'PENDING' && item.key != 'Mileage Image'"
                                :color="controlBgColor(item.value)"
                                :class="controlColorFont(item.value)"
                                >
                                {{item.value}}
                                </v-chip>

                                <!-- <span v-if="item.key == 'Mileage Image'">
                                    <v-img v-if="item.value != null" class="my-3" width="350px" :src="item.value"></v-img>
                                </span> -->

                                <a v-if="item.key == 'Mileage Image' && item.path != null" :href="item.path" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="item.key == 'Mileage Image' && item.path == null" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Dialog Success -->
        <template>
            <v-dialog
            v-model="dialogSuccessCarBook"
            persistent
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5 success--text">
                    <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                    Success!
                </v-card-title>
                <v-card-text class="blackk--text">{{textSuccess}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogSuccessCarBook = false; dialogEditMileage = false; loadDataTableCar();"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

        <!-- Cancel Booking Car -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; cancelBooking()"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-dialog
            v-model="dialogErrorCarBooking"
            persistent
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5 red--text">
                    <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                    Failed!
                </v-card-title>
                <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                <br>
                <v-card-text>
                    <small class="primary--text">
                        <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                    </small> 
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogErrorCarBooking = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

    </v-container>

</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    
    data: () => ({

        loadingDataTable: true,
        loadingText: "", //Data is Loading... Please wait
        textSuccess: "",
        dialogSuccessCarBook: false,
        dialogErrorCarBooking: false,
        takBolehCancel: false,

        // Data Table
        searchDtCarBook: '',
        headers: [],
        allData: [],
        searchObj: {},
        multiSearch: [],
        headerCarBooking: [
            // {text: "Date Applied", value: "dtApplied"},
            {text: "Car Type", value: "carType"},
            {text: "Event/Project Title", value: "eventTitle"},
            {text: "Start Date Time", value: "startDateTime"},
            {text: "End Date Time", value: "endDateTime"},
            {text: "Approval Status", value: "status"},
            {text: "Update Mileage", value: "mil", sortable: false},
            {text: "Cancellation ", value: "cancelCar", sortable: false},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        bodyCarBooking: [
                // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     carType: "Perodua Bezza (BLUE)",  
            //     eventTitle: "Bantuan Mangsa Banjir",  
            //     startDateTime: "2022-01-11 T00:00:00",
            //     endDateTime: "2022-01-11 T00:00:00",
            //     status: "N/A",  
            //     details: "mdi-file-document",  
            // },
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     carType: "Perodua Axia (BLUE)",  
            //     eventTitle: "TNB Site",  
            //     startDateTime: "2022-01-11 T00:00:00",
            //     endDateTime: "2022-01-11 T00:00:00",  
            //     status: "APPROVE",  
            //     details: "mdi-file-document",  
            // },
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     carType: "Ford Ranger (BLUE)",  
            //     eventTitle: "TNB Site",  
            //     startDateTime: "2022-01-11 T00:00:00",
            //     endDateTime: "2022-01-11 T00:00:00",  
            //     status: "APPROVE",  
            //     details: "mdi-file-document",  
            // },
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     carType: "Isuzu D-Max (GREY)",  
            //     eventTitle: "PLUS Meeting",  
            //     startDateTime: "2022-01-11 T00:00:00",
            //     endDateTime: "2022-01-11 T00:00:00",  
            //     status: "N/A",  
            //     details: "mdi-file-document",  
            // },
        ],

        // Display/Edit Mileage
        jsonEditBookCar: {
            idcartype: "",
            startdate: "",
            enddate: "",
            status: "",
            screenshot: "",
            name: "",
            detailevent: "",
            mileagecar: "",
        },
        dialogEditMileage: false,
        loadingImageUpload: false,
        latestMileageUpdate: true,
        takLehUpdateMileage: true,
        imageMil: null,

        // Display Details
        displayDetails: [
            {key: "Event Car", value: "", icon: "mdi-car-select"},    
            {key: "Date Applied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Car", value: "", icon: "mdi-car-multiple"},    
            {key: "Event Name", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Details", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Latest Mileage", value: "", icon: "mdi-speedometer"},    
            {key: "Mileage Image", path: "", icon: "mdi-speedometer"},    
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},    
        ],
        dialogDisplayDetails: false,

        // Cancel Booking Car
        warningDelete: false,
        confirmDelete: false,
        dataToDelete: null,
    }),
    methods: {

        // General
        load(){
            // console.log("Load");
        },

        // Text must in numbering type
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        // Data Table Section
        loadDataTableCar() {

            this.loadingDataTable = true;
            this.loadingText = "Data is Loading... Please wait";
            this.allData = [];
            this.bodyCarBooking = [];
            this.headers = [];

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();

            this.headers = this.headerCarBooking
            // let userName = this.$store.getters.user.name;

             axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.bodyCarBooking = [];
                
                let data = response.data
                // console.log(data);

                for(let i in data){
                    data[i]["dtApplied"] = this.convertDateTime(data[i]["dateapplied"]);
                    // data[i]["carType"] = data[i]["carType"].replace(
                    //     /\w\S*/g,
                    //     function(txt) {
                    //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    //     }
                    // );
                    data[i]["eventTitle"] = data[i]["name"];
                    data[i]["startDateTime"] = this.convertDateTime(data[i]["start"]);
                    data[i]["endDateTime"] = this.convertDateTime(data[i]["end"]);
                    data[i]["detailsEvents"] = data[i]["detailEvent"];
                    data[i]["detailsAll"] = "mdi-file-document";
                    data[i]["mil"] = "mdi-speedometer";
                    data[i]["cancelCar"] = "mdi-car-off";

                    if (data[i]["status"] == "REJECTED") {
                        data[i]["statusMileageUpdated"] = "Reject";
                    }
                    else{

                        if(Date.parse(new Date(data[i]["end"])) - Date.parse(new Date()) < 0)
                        {
                            if (data[i]["mileageCar"] != "" && data[i]["mileageCar"] != null) {
                                data[i]["statusMileageUpdated"] = "YES";
                            }
                            else{
                                data[i]["statusMileageUpdated"] = "NO";
                            }
                        }
                        else{
                            data[i]["statusMileageUpdated"] = "InUse";
                        }

                    }

                }

                for (let i = 0; i < data.length; i++) {
                    if (data[i].idUser == this.$store.getters.user.idEmp){
                        this.bodyCarBooking.push(data[i]);
                    }
                }

                this.allData = this.bodyCarBooking;
                this.loadingDataTable = false;
                this.loadingText = "";

                // console.log(this.bodyCarBooking);
                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDataTable = false;
                this.loadingText = "";
            })

        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },
        
        controlBgColor(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

        isDisable(index) {
            // console.log(index);
            if (index == 5 || index == 6 || index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        // UPDATE MILEAGE
        editMyMileage(data,statusUpdated){
            // console.log(data);
            // console.log(new Date(data.end));

            if (statusUpdated == 'YES') {
                this.takLehUpdateMileage = true;

                this.dialogEditMileage = false;
            }
            // else if(statusUpdated == 'NO'){
            //     this.takLehUpdateMileage = false;
            // }
            else{
                // IF dah lepas dari masa sekarang
                if(Date.parse(new Date(data.end)) - Date.parse(new Date()) < 0)
                {
                    this.takLehUpdateMileage = false;
                }
                else{
                    this.takLehUpdateMileage = true;
                }

                let keyData = Object.keys(data)

                // Json For Sending to API Edit
                // console.log(this.globalListCar);
                // console.log(data.carType);
                for(let i in this.globalListCar){
                    if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                        this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                    }
                }
                this.jsonEditBookCar.startdate = data.start;
                this.jsonEditBookCar.enddate = data.end;
                this.jsonEditBookCar.timed = true;
                this.jsonEditBookCar.status = data.status;
                this.jsonEditBookCar.screenshot = "";
                this.jsonEditBookCar.name = data.name;
                this.jsonEditBookCar.detailevent = data.detailEvent;
                this.jsonEditBookCar.mileagecar = "";

                // Json just for display
                for (let i = 0; i < this.displayDetails.length; i++) {

                    this.displayDetails[i].value = "";
                    
                }

                let myInterval = setInterval(() => {
                    
                    // console.log(keyData);
                    for(let i in this.displayDetails){
                        for(let x in keyData){
                            if (this.displayDetails[i].attribute == keyData[x]) {
                                
                                if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                                    this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                                }
                                else if (keyData[x] == "userContact") {
                                    if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                        this.displayDetails[i].value = data[keyData[x]];
                                    }
                                    else{
                                        this.displayDetails[i].value = "-";
                                    }
                                }
                                else if (keyData[x] == "detailEvent") {
                                    if (data[keyData[x]] != null  && data[keyData[x]] != "") {
                                        this.displayDetails[i].value = data[keyData[x]];
                                    }
                                    else{
                                        this.displayDetails[i].value = "-";
                                    }
                                }
                                else if (keyData[x] == "mileageCar") {
                                    if (data[keyData[x]] != null && data[keyData[x]] != "") {
                                        this.displayDetails[i].value = data[keyData[x]];
                                    }
                                    else{
                                        this.displayDetails[i].value = "-";
                                    }
                                }
                                else{
                                    this.displayDetails[i].value = data[keyData[x]];
                                }
                            }
                        }

                        if (this.displayDetails[i].attribute == "eventCar") {
                            if (data.status == "N/A") {
                                this.displayDetails[i].value = "Daily Car"
                            }
                            else{
                                this.displayDetails[i].value = "Project Car"
                            }
                        }

                    }
                    
                    this.dialogEditMileage = true;

                    clearInterval(myInterval);
                }, 500);
            }

            

        },

        inputUpdMil(e){

            let text = '';
            text = e;

            if (text != '' && text != null ) {
                this.latestMileageUpdate = false;
            }
            else{
                this.latestMileageUpdate = true;
            }
        },

        handleFileUpload(data){

            this.loadingImageUpload = true;

            let formData = new FormData();

            formData.append("files", data);

            axios.post(process.env.VUE_APP_API_URL + `employees/upload`, formData, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                // console.log(response);
                this.jsonEditBookCar.screenshot = response.data.filepath;
                // console.log(this.jsonEditBookCar.mileagecar);
                this.loadingImageUpload = false;
            })
            .catch((error) => {
                console.log(error.response);
                this.loadingImageUpload = false;
            });
        },

        cancelUpdateBooking(){
            this.dialogEditMileage = false;
            this.$refs.form.reset();
        },

        updateCarBooking(){

            
            
            if (this.jsonEditBookCar.screenshot == "") {
                this.jsonEditBookCar.screenshot = null;
            }

            // console.log(this.jsonEditBookCar);

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.textSuccess = "Mileage is succesfully update in system.";
                this.dialogSuccessCarBook = true;
                this.loadDataTableCar();
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessCarBook = false;
                this.dialogErrorCarBooking = true;
            })
        },


        // DISPLAY DETAILS
        openFileDoc(item){
            // console.log(item);

            // for (let i = 0; i < this.displayDetails.length; i++) {

            //     if (this.displayDetails[i].key == 'Mileage Image') {
            //         this.displayDetails[i].value = null;
            //     }
            //     else{
            //         this.displayDetails[i].value = "";
            //     }
                
            // }

            // console.log(this.displayDetails);

            let myInterval = setInterval(() => {
                
                if (item.status == "N/A") {
                    this.displayDetails[0].value = "Daily Car";
                }
                else{
                    this.displayDetails[0].value = "Project Car";
                }
                this.displayDetails[1].value = this.convertDateTime(item.dateapplied);
                this.displayDetails[2].value = this.convertDateTime(item.start);
                this.displayDetails[3].value = this.convertDateTime(item.end);
                this.displayDetails[4].value = item.carType;
                this.displayDetails[5].value = item.name;

                // this.displayDetails[6].value = item.detailEvent;
                if (item.detailEvent != null && item.detailEvent != "") {
                    this.displayDetails[6].value = item.detailEvent;
                }
                else{
                    this.displayDetails[6].value = "-";
                }

                // this.displayDetails[7].value = item.mileageCar;
                if (item.mileageCar != null && item.mileageCar != "") {
                    this.displayDetails[7].value = item.mileageCar + " KM"; 
                }
                else{
                    this.displayDetails[7].value = "-";
                }
                this.displayDetails[8].path = item.screenshot;
                this.displayDetails[9].value = item.status;

                this.dialogDisplayDetails = true;

                // console.log(this.displayDetails);

                clearInterval(myInterval);
            }, 500);
            
            
        },

        confirmationDelete(data){

            this.dataToDelete = data
            this.warningDelete = true;
  
        },

        cancelBooking(){

            // console.log(this.dataToDelete);

            let idCar = null;
            for(let i in this.globalListCar){
                // console.log(this.globalListCar[i].label);
                if (this.globalListCar[i].label.toUpperCase() == this.dataToDelete.carType) {
                    idCar = this.globalListCar[i].id
                }
            }

            axios.get(process.env.VUE_APP_API_URL + "car/delbookcar?idcartype="+idCar+"&startdate="+this.dataToDelete.start+"&enddate="+this.dataToDelete.end,
            {
                headers: {
                    "Authorization": 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.textSuccess = "Car Booking is succesfully deleted in system.";
                this.dialogSuccessCarBook = true;
                this.loadDataTableCar();
                bus.$emit('changeIt', 'loadDataCalendar');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessCarBook = false;
                this.dialogErrorCarBooking = true;
            })
        },


        // This function for load every sec to check the changes in data
        // intervalFetchData: function () {
        //     setInterval(() => {    
        //         this.loadDataTable();
        //         }, 1000);    
        // }
    },
    mounted(){
        this.load();
        this.loadDataTableCar();
        // this.intervalFetchData();
    },
    computed:{
        filteredData(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
        // checkChangesData(){
        //     if (window.loadDt == true) {
        //         console.log(window.loadDt);
        //         return this.loadDataTableCar();
        //     }
        //     else{
        //         return false;
        //     }
        // }
    },
    created (){
        bus.$on('changeIt', (data) => {

            if (data == "loadNotify") {
                this.loadDataTableCar();
            }

        })
    }
}
</script>

<style lang="scss">
@import '~scss/main';


</style>
