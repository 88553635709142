<template>
    <v-container root dark> 

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-car</v-icon>
            Car Booking
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        show-arrows="mobile"
        >
            <v-tab v-for="item in tabsCarBooking" :key="item.tab" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Inbox -->
            <v-tab-item class="container-tab-modul">

                <v-container>
                    <inbox-datatable></inbox-datatable>
                </v-container>
                
            </v-tab-item>

            <!-- Tab Calendar -->
            <v-tab-item class="container-tab-modul">
               
                <calendar-calendar></calendar-calendar>

            </v-tab-item>

            <!-- Tab Info Car -->
            <v-tab-item class="container-tab-modul">
                
                <car-info></car-info>
                
            </v-tab-item>
            

        </v-tabs-items>

        



    </v-container>
</template>

<script>
import dtCarBooking from '@/components/carBooking/Datatable'
import calCarBooking from '@/components/carBooking/Calendar'
import infoCarBooking from '@/components/carBooking/CarInfo'
export default {
    components: {
        'inbox-datatable': dtCarBooking,
        'calendar-calendar': calCarBooking,
        'car-info': infoCarBooking,
    },
    data: () => ({

        
        tabs: null,
        tabsCarBooking: [
            {tabName: "Inbox", icon: "mdi-inbox-multiple"},
            {tabName: "Book Car", icon: "mdi-calendar-multiselect"},
            {tabName: "Info", icon: "mdi-car-info"},
        ],
        


        // Tab Info Car
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],

    }),
    methods: {

    },
    mounted(){
        // console.log(this.$store.getters.user);
        // console.log(this.$store);
    },
}


</script>

<style lang="scss">
@import '~scss/main';

</style>
